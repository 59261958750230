<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>招聘会</el-breadcrumb-item>
            <el-breadcrumb-item>添加招聘会</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
           <pre>{{form.jobFairIntroduce}}</pre>
            <div class="add">
                <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                    <el-form-item label="招聘会名称" prop="jobName">
                        <el-input v-model="form.jobName" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="主办方" prop="organizer">
                        <el-input v-model="form.organizer" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="举办时间" >
                        <el-date-picker
                        v-model="value1"
                        :picker-options="pickerOptions"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="地区" prop="holdField" v-show="userInfo.orgId==0">
                        <el-select v-model="form.orgId" placeholder="请选招聘会地区">
                            <el-option
                            v-for="item in orglist"
                            :key="item.orgId"
                            :label="item.districtName"
                            :value="item.orgId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="举办会场" prop="holdField">
                        <el-input v-model="form.holdField" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="公交线路">
                        <el-input v-model="form.trafficRoutes" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="回放视频">
                        <el-input v-model="form.videoUrl" placeholder="" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="是否网络招聘会">
                        <el-radio v-model="form.network" :label="0">否</el-radio>
                        <el-radio v-model="form.network" :label="1">是</el-radio>
                    </el-form-item>
                    <el-form-item label="负责人" prop="contacts">
                        <el-input v-model="form.contacts" style="width:200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="负责人电话" prop="contactsPhone">
                        <el-input v-model="form.contactsPhone"  style="width:200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="宣传图" >
                        <upload-img :modeldata="form" @changeurl="getimgurl" style="margin-top:15px;"></upload-img>
                    </el-form-item>
                    <el-form-item label="招聘会介绍">
                         <el-input type="textarea" v-model="form.jobFairIntroduce" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="媒体宣传">
                         <el-input type="textarea" v-model="form.mediaPublicity" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="超值服务套餐">
                         <el-input type="textarea" v-model="form.jobExplain" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="展位设置方案">
                         <el-input type="textarea" v-model="form.boothSettingScheme" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="参与办法">
                         <el-input type="textarea" v-model="form.participatoryApproach" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('form')">确定添加</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import serverurl from '@/api/booth'
import _api from '@/api/index'
export default {
    data(){
        return{
            userInfo:JSON.parse(window.localStorage.getItem("userInfo")),
            orglist:JSON.parse(localStorage.getItem("org")),
            value1:"",//举办时间
            pickerOptions: { // 限制收货时间不让选择今天以前的
            　　disabledDate(time) {
            　　　　return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
            　　}
            },
            form:{
                jobName:"",
                organizer:"",//主办方
                holdField:"",//会场
                trafficRoutes:"",//公交线路
                contacts:"",//负责人
                videoUrl:"",//视频
                contactsPhone:"",//联系电话
                url:"",//宣传图片
                jobFairIntroduce:"",//介绍
                mediaPublicity:"",//媒体宣传
                jobExplain:"",//超值服务套餐
                boothSettingScheme:"",//展位设置方案
                participatoryApproach:"",//参与办法
                holdStartTime:"",//开始时间
                holdEndTime:"",//结束时间
                network:0,//是否网络招聘会
                orgId:null,

            },
            rules:{
                jobName:[
                    { required: true, message: '请输入招聘会名称', trigger: 'blur' },
                ],
                organizer:[
                    { required: true, message: '请输入主办方名称', trigger: 'blur' },
                ],
                holdField:[
                    { required: true, message: '请输入举办会场', trigger: 'blur' },
                ],
                contacts:[
                    { required: true, message: '请输入负责人', trigger: 'blur' },
                ],
                contactsPhone:[
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                ]
            }
        }
    },
    methods:{
        getimgurl(){

        },
        onSubmit(form){
            console.log()
            if(this.value1=="" || this.value1==null || this.value1.length<=0){
                this.$message.error("请选择招聘会时间！")
                return
            }
            if(this.form.url!=""){
                this.$refs[form].validate((valid)=>{
                    if (valid) {
                        //this.form.jobFairIntroduce=this.form.jobFairIntroduce.replace(/\n/g, '<br />')
                        this.form.holdStartTime=this.value1[0]
                        this.form.holdEndTime=this.value1[1]
                        //alert('submit!');
                        _api.post(serverurl.insertJob,this.form).then(res=>{
                            console.log(res)
                            if(res.success){
                                this.$message.success("添加招聘会成功！")
                                Object.keys(this.form).forEach(key=>(this.form[key]=""))
                                this.value1=[]
                            }
                        })

                    } else {
                        return false;
                    }
                })
            }else{
                this.$message.error("宣传图必传！")
            }

        }
    }
}
</script>
<style scoped>
.add{ width:900px;}
</style>
